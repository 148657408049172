@charset "UTF-8";

@import "variable";

.recommended-categories {
    font-family: "Helvetica Neue";
    transition: all 0.25s ease;
    background: url("images/peachan-blinking.gif") no-repeat;
    background-position: 75% 75px;
    background-size: 75px;
    width: 300px;
    height: auto;
    min-height: 75px;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: 1000;
    display: none;

    .categories {
        display: none;
        background: white;
        padding: 10px 10px 10px 10px;
        border: 1px solid $light-green;
        border-radius: 5px;
        position: absolute;
        right: 15px;
        min-width: 120px;
        max-width: 190px;

        .heading {
            margin-bottom: 10px;
            padding-right: 20px;
            text-align: left;
            font-size: 13px;
            line-height: 16px;
            font-family: "Helvetica Neue Medium", "HelveticaNeue-Medium", "Lucida Grande", "Helvetica Neue";
        }

        .close {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }

        .tags {
            text-align: left;

            >li.tag {
                display: inline-block;
                margin-bottom: 5px;
                margin-right: 5px;

                a {
                    font-size: 11px;
                    font-weight: 100;
                    color: $grey-light3;
                    background: $grey-light2;
                    border: 1px solid $grey-light;
                    border-radius: 5px;
                    transition: all 0.25s ease;
                    padding: 0px 4px;
                    display: block;

                    &:hover {
                        background: $grey-light;
                    }
                }
            }
        }

        &:after,
        &:before {
            content: '';
            position: absolute;
            border-style: solid;
            border-width: 15px 15px 0;
            border-color: #FFFFFF transparent;
            display: block;
            width: 0;
            z-index: 1;
            bottom: -15px;
            right: 25px;
        }

        &:before {
            border-color: $light-green transparent;
            bottom: -16px;
        }
    }
}
